<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i class="fal fa-camera-alt" style="margin: 0 10px 0 0; font-size: 20px"></i>
            <span @click="main()">{{ $t("change_password.title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'scan_camera.create'">
            <span v-show="true">/</span>
            <span>{{ $t("AddNew") }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    main() {
      this.$router
        .push({
          name: "change.password.index",
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped lang="scss"></style>
